import React from 'react'
import { graphql, Link } from 'gatsby'
import PropTypes from 'prop-types'
import Layout from 'components/Layout'
import SEO from 'components/Seo'
import { IntlProvider } from 'contexts/intl/IntlContext'
import { Disqus, CommentCount } from 'gatsby-plugin-disqus'
import dateFormat from 'dateformat'

export const query = graphql`
  query BlogPostByID($id: String!) {
    file(relativePath: { eq: "joshua-reddekopp-syymxsdnj54-unsplash.jpg" }) {
      absolutePath
      accessTime
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid
          src
          sizes
        }
        resize {
          height
          width
        }
      }
      ext
      name
      publicURL
      size
      prettySize
    }
    site {
      siteMetadata {
        title
        ogTitle
        twitterTitle
        description
        ogDescription
        twitterDescription
        image
        url
        siteUrl
        author
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
        readingTime {
          text
        }
      }
      frontmatter {
        publishedAt(formatString: "MMMM DD, YYYY")
        title
        description
        topics
        category
        keywords
        featuredPost
        showDefaultImage
      }
    }
  }
`

const Post = ({ data, location }) => {
  const { markdownRemark: post } = data
  let disqusConfig = {
    url: `${data.site.siteMetadata.url + location.pathname}`,
    identifier: post.id,
    title: post.title,
  }
  var topics = post.frontmatter.topics.map(node => node) //.slice(0, 3)
  return (
    <IntlProvider pathname={location.pathname}>
      {({ formatDate, formatMessage, getPath }) => (
        <Layout>
          <SEO
            lang={post.frontmatter.locale}
            title={post.frontmatter.title}
            description={post.frontmatter.description}
            keywords={post.frontmatter.keywords.toString()}
            isBlogPost={false}
            ogTitle={post.frontmatter.title}
            ogUrl={data.site.siteMetadata.siteUrl + '/' + post.frontmatter.slug}
            ogImageUrl={data.file.publicURL}
            ogImageSecureUrl={data.file.publicURL}
            ogImageAlt='mihir pipermitwala'
            ogImageWidth={data.file.childImageSharp.resize.width}
            ogImageHeight={data.file.childImageSharp.resize.height}
            ogImageType={data.file.ext == '.jpg' ? 'image/jpg' : 'image/png'}
            ogDescription={post.frontmatter.description}
            ogKeywords={post.frontmatter.keywords.toString()}
            ogLocale={post.frontmatter.locale}
            twitterTitle={post.frontmatter.title}
            twitterDescription={post.frontmatter.description}
            twitterCreator={data.site.siteMetadata.title}
            siteName={data.site.siteMetadata.title}
            datePublished={dateFormat(post.frontmatter.published_at, 'yyyy-mm-dd')}
            dateModified={dateFormat(post.frontmatter.published_at, 'yyyy-mm-dd')}
          />
          <article className='post'>
            <h1 className='post__title'>{post.frontmatter.title}</h1>

            {post.frontmatter.publishedAt && (
              <time dateTime={post.frontmatter.publishedAt} className='post__time'>
                {formatDate(post.frontmatter.publishedAt, {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </time>
            )}
            {post.frontmatter.showDefaultImage == true && (
              <div className='post__main-image'>
                <img src={data.file.childImageSharp.fluid.src} alt={data.file.name} width='100%' />
              </div>
            )}
            <div dangerouslySetInnerHTML={{ __html: post.html }} />
            <span className='tags_title'>Tags:</span>
            <div className='tags'>
              {topics.map(topic => (
                <Link to={'/topics/' + topic} className='tag'>
                  {topic}
                </Link>
              ))}
            </div>
            <CommentCount config={disqusConfig} placeholder={''} />
            <Disqus config={disqusConfig} />
          </article>
          <div className='mt-6'>
            <p className='has-text-centered'>
              {formatMessage({ id: 'checkOut.posts' })}{' '}
              <Link to={getPath('blogs')}>{formatMessage({ id: 'stack.posts' })}</Link>
            </p>
          </div>
        </Layout>
      )}
    </IntlProvider>
  )
}

Post.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default Post
